<template>
  <ion-page>
    <MobileHeader></MobileHeader>
    <ion-content>
      <div class="content-wrapper">
        <div class="img-wrapper">
          <img :src="logo" alt="" />
        </div>
        <h4 class="termsTitle" data-test="title">{{ termsDoc?.title }}</h4>
        <SanityBlocks :blocks="termsDoc?.mainText" :serializers="serializers" />
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent } from "@ionic/vue";
import { useSanityStore, useLocaleStore } from "@/store";
import MobileHeader from "@/components/Common/MobileHeader/MobileHeader.vue";
import { SanityBlocks } from "sanity-blocks-vue-component";
export default {
  name: "terms-and-conditions",
  components: {
    IonPage,
    MobileHeader,
    IonContent,
    SanityBlocks,
  },
  setup() {
    const sanityStore = useSanityStore();
    const localeStore = useLocaleStore();
    return { sanityStore, localeStore };
  },
  mounted() {
    this.sanityStore.requestTermsAndConditionsContent();
  },
  computed: {
    themeName() {
      return this.localeStore.getCurrentLocaleTheme.toLowerCase();
    },
    logo() {
      const extension = ["global", "ca", "sg", "au"].includes(this.themeName)
        ? "svg"
        : "png";
      return require(`@/common/assets/welcome/logo/${this.themeName}.${extension}`);
    },
    termsDoc() {
      return this.sanityStore.getTermsAndConditionsContent;
    },
  },
};
</script>
<style lang="scss">
.content-wrapper ul {
  padding-left: 27px;
}
</style>
<style scoped lang="scss">
@import "@/common/theme/breakpoints.scss";
.content-wrapper {
  padding: 24px;
  padding-top: 0;
  text-align: left;
  & .termsTitle {
    color: var(--ion-color-secondary);
    margin-top: 0;
  }
}

h4 {
  font-size: 28px;
}
.content-wrapper ul {
  padding-left: 27px;
}
.img-wrapper {
  margin: auto;
  width: 111px;
  margin-bottom: 35px;
  margin-top: 35px;
}
@include breakpoint(medium) {
  .content-wrapper {
    margin: auto;
    padding: 50px;
    max-width: 700px;
  }
  .img-wrapper {
    margin-left: 0;
  }
}
</style>
