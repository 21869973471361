<template>
  <header role="banner">
    <div class="locale-selector" v-if="localeStore.getShowLocaleSwitcher">
      <LocaleSelector
        data-test="locale-selector"
        :selectedLocaleCode="localeStore.currentLocale"
        :allowedLocaleCodes="localeStore.getAllowedLocaleCodes"
      ></LocaleSelector>
    </div>
  </header>
</template>
<script>
import { defineComponent } from "vue";
import LocaleSelector from "@/components/Common/LocaleSelector/LocaleSelector.vue";
import { useLocaleStore } from "@/store";

export default defineComponent({
  name: "DesktopLocaleSelector",
  components: {
    LocaleSelector,
  },
  setup() {
    const localeStore = useLocaleStore();
    return { localeStore };
  },
});
</script>

<style lang="scss" scoped>
@import "@/common/theme/breakpoints.scss";

.locale-selector {
  display: none;
}

/* Desktop layout */
@include breakpoint(medium) {
  .locale-selector {
    display: inline;
    position: absolute;
    top: 20px;
    right: 20px;
    border: 1px solid var(--lighter-grey);
    border-radius: 8px;
    background: var(--background-white);
    z-index: var(--z-index-locale-selector);
  }
  header {
    width: 106px;
    height: 84px;
    position: absolute;
    right: 0;
  }
}
</style>
